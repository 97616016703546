<script setup>
const favs = useFavorites();
await favs.loadCars();

const {breakpoint} = useBreakpoints();
const slideSize = computed(() => breakpoint.value ? 202 : 182);
const opened = ref(true);

onMounted(() => {
  if (favs.cars.value.length) {
    document.documentElement.classList.add('favs');
  }
})

onUnmounted(() => {
  document.documentElement.classList.remove('favs');
})

watch(() => favs.count.value, (v) => {
  if (v) {
    document.documentElement.classList.add('favs');
  } else {
    document.documentElement.classList.remove('favs');
  }
})

watch(() => opened.value, (v) => {
  if (v) {
    document.documentElement.classList.remove('favs-closed');
  } else {
    document.documentElement.classList.add('favs-closed');
  }
})

</script>

<template>
  <div class="fixed -bottom-[125px] left-0 right-0 bg-dark-gray z-60 text-white font-extralight transition-position"
       :class="{'!bottom-0': favs.count.value, 'py-2': !opened}">
    <Container no-paddings>
      <div class="h-8 text-white flex items-center justify-end select-none">
        <div class="flex items-center gap-2">
          <IconCompare v-show="!opened" class="!stroke-white !w-5 !h-5" thin />
          <NuxtLink v-show="!opened" class="cursor-pointer text-white" :href="{name: 'favorites'}" >Przejdź do porównania</NuxtLink>
          <IconChevronDown class="!fill-white cursor-pointer"
                           :class="[{'rotate-180': !opened}]"
                           @click="opened = !opened"/>
        </div>

      </div>
      <div class="grid grid-cols-1 gap-y-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center pb-8" v-show="opened">
        <div class="lg:col-span-2 xl:col-span-3">
          <SlideControl :size=slideSize :gap=0 class="!py-0">
            <template v-for="favoriteCar in favs.cars.value">
              <SlideItem class="border-r-white border-r last:border-r-0"
                         :class="[{'w-[202px] min-w-[202px]': breakpoint, 'w-[182px] min-w-[182px]':!breakpoint}]"
                         custom-size>
                <div class="text-lg font-normal leading-none">{{ favoriteCar.brandName }}</div>
                <div class="text-base whitespace-nowrap max-w-[178px] overflow-ellipsis overflow-hidden">{{ favoriteCar.versionName }}</div>
                <ButtonPlain class="text-gray-400 text-xs font-extralight !px-0" @click="favs.remove(favoriteCar)">
                  <template v-slot:rightSlot>
                    <IconClose class="!w-4 !h-4" />
                  </template>
                  <template v-slot:default>Usuń</template>
                </ButtonPlain>
              </SlideItem>
            </template>
            <SlideItem class="border-r-white border-r last:border-r-0"
                       :class="[{'w-[202px] min-w-[202px]': breakpoint, 'w-[182px] min-w-[182px]':!breakpoint}]"
                       custom-size
                       v-if="favs.count.value === 1">
              <div class="font-extralight text-sm"
                   :class="[{'pt-3': breakpoint}]">
                <template v-if="breakpoint">
                  <div>Dodaj min. 2 samochody</div>
                  <div>aby porównać</div>
                </template>
                <template v-else>
                  <div>Dodaj minimum</div>
                  <div>2 samochody</div>
                  <div>aby porównać</div>
                </template>
              </div>
            </SlideItem>
          </SlideControl>
        </div>
        <div class="col-span-1">
          <ButtonRounded color="transparent" class="ml-auto md:max-w-[230px] text-white border-white hover:text-white hover:border-white" :href="{name: 'favorites'}">
            <div class="!flex justify-center items-center gap-2">
              <IconCompare class="!stroke-white !w-5 !h-5" thin />
              <div>Przejdź do porównania</div>
            </div>
          </ButtonRounded>

        </div>
      </div>
    </Container>
  </div>
</template>
