<script setup>
const props = defineProps({
  thin: Boolean,
  car: Object
})

const emits = defineEmits(['clear'])

</script>

<template>
  <div class="rounded-lg overflow-hidden shadow-box select-none bg-white pointer-events-auto"
       :class="{'min-w-[300px]':!thin, 'min-w-[280px]':thin}">
    <div class="px-4 pt-2 font-extralight transition-all relative">
      <CarImageBox :car="car" class='mb-2 -mx-4' placeholder choose-view/>
      <div class="absolute top-0 left-0 right-0 z-20 h-[180px] flex flex-col justify-center items-center gap-2">
        <div class="text-3xl font-normal">Ups...</div>
        <div>Spróbuj z mniejszą ilością filtrów</div>
        <div>
          <ButtonPlain @click="emits('clear')">
          <template v-slot:default>Wyczyść wszystko</template>
          <template v-slot:rightSlot><IconTrashCan class="!w-4 !h-4"/></template>
        </ButtonPlain>
        </div>
      </div>
      <div ref="refId" class="flex justify-between overflow-hidden gap-10 pt-2">
        <EffectEmpty class="h-3"/>
        <EffectEmpty class="h-3"/>
      </div>
      <div class="text-lg font-normal mt-2 whitespace-nowrap text-ellipsis overflow-hidden border-b-gray-400 border-b pb-3 pt-2.5">
        <EffectEmpty class="h-5"/>
      </div>
    </div>
    <div class="px-4 pt-2 pb-6 font-extralight">
      <div class="space-y-2.5 pt-2.5">
        <div>
          <div>
            <div class="col-span-2 grid grid-cols-2 gap-4 items-center">
              <div>
                <div class="text-xs mb-2">
                  <EffectEmpty />
                </div>
                <div class="text-azure ">
                  <EffectEmpty class="!h-8" />
                </div>
              </div>
              <div class="pt-4">
                <EffectEmpty />
              </div>
            </div>
          </div>
          <div ref="refPrices" class="grid grid-cols-2 gap-4 pt-4">
            <div>
              <EffectEmpty class="h-2.5 mb-2"/>
              <EffectEmpty class="h-4"/>
            </div>
            <div>
              <EffectEmpty class="h-2.5 mb-2"/>
              <EffectEmpty class="h-4"/>
            </div>
            <div>
              <EffectEmpty class="h-2.5 mb-2"/>
              <EffectEmpty class="h-4"/>
            </div>
            <div>
              <EffectEmpty class="h-2.5 mb-2"/>
              <EffectEmpty class="h-4"/>
            </div>
          </div>
          <div class="mt-2.5">
            <EffectEmpty class="!h-10"/>
          </div>
        </div>
        <div>
          <div ref="refIcons" class="grid grid-cols-2 gap-2.5 pt-1 overflow-hidden -mb-1">
            <div class="flex gap-1 items-center">
              <EffectEmpty class="!h-8 !w-8 shrink-0" />
              <EffectEmpty class="h-2.5"/>
            </div>
            <div class="flex gap-1 items-center">
              <EffectEmpty class="!h-8 !w-8 shrink-0" />
              <EffectEmpty class="h-2.5"/>
            </div>
            <div class="flex gap-1 items-center">
              <EffectEmpty class="!h-8 !w-8 shrink-0" />
              <EffectEmpty class="h-2.5"/>
            </div>
            <div class="flex gap-1 items-center">
              <EffectEmpty class="!h-8 !w-8 shrink-0" />
              <EffectEmpty class="h-2.5"/>
            </div>
            <div class="flex gap-1 items-center">
              <EffectEmpty class="!h-8 !w-8 shrink-0" />
              <EffectEmpty class="h-2.5"/>
            </div>
            <div class="flex gap-1 items-center">
              <EffectEmpty class="!h-8 !w-8 shrink-0" />
              <EffectEmpty class="h-2.5"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
