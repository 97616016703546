<script setup>
const props = defineProps({
  tags: Map
})

const emits = defineEmits(['click'])

const {onWindowEvent} = useWindowEvents()

const more = ref(99)
const selected = defineModel('selected', {default: () => new Map});

const toggleSelected = (id) => {
  if (selected.value.has(id)) {
    selected.value.delete(id)
  } else {
    selected.value.set(id, true);
    emits('click',props.tags.get(id))
  }
}


const refContainer = ref();
const refMore = ref();
const expanded = ref(false);

const visibleElementsCount = () => {
  if (process.server || expanded.value) return props.tags.size;

  const containerWidth = refContainer.value.getInnerWidth();
  const moreWidth = 65;
  const tags = refContainer.value.querySelectorAll('.☁️');

  let size = moreWidth + 4;
  let count = 0;
  for (let tag of tags) {
    const {width: tagSize} = tag.getBoundingClientRect();
    if (size + Math.round(tagSize) + 8 > containerWidth) {
      return count;
    }
    size += Math.round(tagSize) + 8;
    count++;
  }
  return count;
}

onMounted(() => {
  nextTick(() => {
    more.value = visibleElementsCount();
  })
})


onWindowEvent('resize', () => {
  nextTick(() => {
    more.value = visibleElementsCount();
  })
})

</script>

<template>
  <div ref="refContainer"
       class="flex flex-wrap gap-2 w-full font-extralight text-sm overflow-hidden items-center"
       :class="{'h-6': !expanded}"
  >
    <template v-for="([id, label],idx) of tags">
      <div class="☁️ | flex rounded-full bg-gray-100 px-2 cursor-pointer select-none shrink-0 grow-0"
           :class="[{'text-celadon': selected.has(id)}]"
           :style="{'order':(idx+1) * 2}"
           @click="toggleSelected(id)">
        <IconCheckGreen :class="[{'grayscale opacity-30': !selected.has(id)}]" />
        {{ label }}
      </div>
    </template>
    <ButtonToggle ref="refMore"
                  class="text-azure font-light w-[65px] whitespace-nowrap"
                  :style="{'order': !expanded ? (more +1 ) * 2 - 1 : 1000}"
                  @toggle="(t) => expanded = t">
      <template v-slot:default>+{{ props.tags.size - more }} więcej</template>
      <template v-slot:toggled>- mniej</template>
    </ButtonToggle>
  </div>
</template>
